import { Flex } from '@chakra-ui/layout'
import { Box, Icon, useToast } from '@chakra-ui/react'
import { useAnimation } from 'framer-motion'
import React, { useCallback, useEffect, useState } from 'react'
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md'
import { createNotification } from '../../../gql/mutations/putNotification'
import { getIsLiked } from '../../../gql/queries/getIsPostLiked'
import { getAuthToken, useUser } from '../../../helper/auth'
import { MotionBox } from '../../Motion'

interface LikeProps {
  onLikeChanged: (val: boolean) => void
  likeCount: number
  type: 'post' | 'comment' | 'hotel' | 'inspiration'
  authorId?: string | number
  id: string | number
}
// eslint-disable-next-line
const Like: React.FC<LikeProps> = ({ onLikeChanged, likeCount, type, authorId, id }) => {
  const controls = useAnimation()
  const user = useUser()
  const [isLiked, setIsLiked] = useState(false)
  const [count, setCount] = useState(likeCount)
  const toast = useToast()

  useEffect(() => setCount(likeCount), [likeCount])

  useEffect(() => {
    if (!user.isLoggedIn) return
    getIsLiked(getAuthToken(), type, id).then(console.log)
    getIsLiked(getAuthToken(), type, id)
      .then(res => setIsLiked(res.data.likedByUser?.liked ?? false))
  }, [id, type, user.isLoggedIn])

  const handleLike = useCallback(() => {
    if (!user.isLoggedIn) {
      return toast({
        description: 'Du musst eingeloggt sein um diese Aktion auszuführen',
        status: 'info',
        duration: 3000,
      })
    }
    controls.start({
      scale: [1, 1.4, 1.2, 1],
      transition: { duration: 0.35 },
    })

    if (isLiked) {
      // TODO: remove non null assertion when type is fixed
      user.unlike?.(id, type)
      setCount(count - 1)
    } else {
      user.like?.(id, type)
      if (id && authorId) {
        createNotification(getAuthToken(), +id, +authorId, 'postLiked')
      }
      setCount(count + 1)
    }

    onLikeChanged(!isLiked)
    setIsLiked(!isLiked)
  }, [isLiked, id, user.isLoggedIn, user.like, user.unlike, type, onLikeChanged, count, authorId])

  return (
    <Flex
      data-analytics-like={`${type} ${id}`}
      alignItems="center"
      justifyContent="center"
      color={isLiked ? 'red.400' : 'inherit'}
      onClick={handleLike}
      cursor="pointer"
      userSelect="none"
      transition="color .35s"
      _hover={{ color: 'red.400' }}
    >
      <MotionBox animate={controls} display="flex">
        <Icon w="32px" h="32px" as={isLiked ? MdFavorite : MdFavoriteBorder}/>
      </MotionBox>
      <Box as="span" px=".5em">{count}</Box>
    </Flex>
  )
}

export default Like
