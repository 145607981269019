import { CheckIcon } from '@chakra-ui/icons'
import { Avatar, Badge, Box, Button, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Portal, Text, useDisclosure } from '@chakra-ui/react'
import { IonBackButton, IonButton, IonButtons, IonIcon, IonTitle, IonToolbar, useIonRouter } from '@ionic/react'
import React, { useCallback, useEffect, useState } from 'react'
import { markAllNotificationsAsRead, markNotificationAsRead } from '../gql/mutations/putNotificationMarkAsRead'
import { getCompressedImageUrl } from '../helper/asset'
import { useUser } from '../helper/auth'
import { useNotifications } from '../helper/notifications'
import Notification from './Notification'
import FocusLock from 'react-focus-lock'

interface IHeaderProps {
  title?: string
  showBackButton?: boolean
}

const Header: React.FC<IHeaderProps> = ({ showBackButton, title }) => {
  const notifications = useNotifications()
  const user = useUser()
  const router = useIonRouter()
  const popoverState = useDisclosure()
  const [isMarkingAsRead, setIsMarkingAsRead] = useState<boolean>(false)

  useEffect(() => setIsMarkingAsRead(false), [notifications])

  const markAsRead = useCallback((id: number) => {
    if (user.token) markNotificationAsRead(user.token, id)
    popoverState.onClose()
  }, [user.token, popoverState])

  const markAllAsRead = useCallback(async () => {
    setIsMarkingAsRead(true)
    if (user.token) await markAllNotificationsAsRead(user.token)
  }, [user.token])

  const closeNotifications = () => {
    popoverState.onClose()
  }

  return (
    <IonToolbar>
      {title && <IonTitle>{title}</IonTitle>}
      {showBackButton && (
        <IonButtons slot="start">
          <IonBackButton defaultHref="/"/>
        </IonButtons>
      )}
      <IonButtons slot="end">
        {user.data && (
          <>
            {!!notifications.totalUnread && notifications.totalUnread > 0 && (
              <Badge pos="absolute" fontSize="10px" h="15px" minW="15px" borderRadius="50%" top="5px" left="25px" variant="solid" bg="red.500" zIndex={100}>
                {notifications.totalUnread}
              </Badge>
            )}
            <Popover
              {...popoverState}
              closeOnBlur={false}
            >
              <PopoverTrigger>
                <IonButton>
                  <IonIcon slot="icon-only" src="/assets/bell.svg"/>
                </IonButton>
              </PopoverTrigger>
              <Portal>
                {/* workaroung for iphone bug that makes the popover lose focus on click + drag scroll */}
                <Box display={!popoverState.isOpen ? 'none' : 'block'} w="100%" h="100%" position="absolute" onClick={closeNotifications}>
                  <FocusLock>
                    <PopoverContent height="100%" maxHeight="70vh" overflowY="auto">
                      {notifications.count! > 0 && (
                        <Button
                          isLoading={isMarkingAsRead}
                          onClick={markAllAsRead}
                          leftIcon={<CheckIcon/>}
                          p="15px 0"
                          _focus={{
                            boxShadow: 'none',
                          }}
                        >Alle als gelesen markieren
                        </Button>
                      )}
                      <PopoverArrow/>
                      {notifications.count! > 0
                        ? (
                          <PopoverBody bg="var(--ion-card-background)" p="0">
                            {notifications.data?.sort((a, b) => Number(b?.creationDate) - Number(a?.creationDate)).map((notification) => (
                              notification && <Notification key={notification.id} markAsRead={() => markAsRead(Number(notification.id))} {...notification}/>
                            ))}
                          </PopoverBody>
                          )
                        : (
                          <PopoverBody bg="var(--ion-card-background)" p="0">
                            <Text p={5} lineHeight="1em">Keine neuen Benachrichtigungen</Text>
                          </PopoverBody>
                          )}
                    </PopoverContent>
                  </FocusLock>
                </Box>
              </Portal>
            </Popover>
            <IonButton onClick={() => router.push(user.data?.user?.id ? `/profile/${user.data.user.id}` : '/login')}>
              <Avatar size="sm" slot="icon-only" src={getCompressedImageUrl(user.data.user?.customer?.images?.[0]?.fullpath)}/>
            </IonButton>
          </>
        )}
      </IonButtons>
    </IonToolbar>

  )
}

export default Header
