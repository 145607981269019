import { Box, Icon, useToast } from '@chakra-ui/react'
import { useAnimation } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { MdOutlineBookmarkAdd, MdOutlineBookmarkRemove } from 'react-icons/md'
import { getIsHotelBookmarked, getIsInspirationBookmarked, getIsPostBookmarked } from '../../../gql/queries/getIsBookmarked'
import { getAuthToken, useUser } from '../../../helper/auth'
import { MotionFlex } from '../../Motion'

interface BookmarkProps {
  type: 'post' | 'hotel' | 'inspiration'
  id: number | string
  onClick?: () => void
}

export const Bookmark: React.FC<BookmarkProps> = ({ id, onClick, type }) => {
  const controls = useAnimation()
  const user = useUser()
  const toast = useToast()
  const [isBookmarked, setIsBookmarked] = useState(false)

  useEffect(() => {
    if (!user.isLoggedIn) return
    const query = type === 'post' ? getIsPostBookmarked(id, getAuthToken()) : type === 'hotel' ? getIsHotelBookmarked(id, getAuthToken()) : getIsInspirationBookmarked(id, getAuthToken())
    query.then(res => {
      setIsBookmarked(res.data.bookmarkedByUser?.bookmarked ?? false)
    })
  }, [id, user.isLoggedIn])

  const handleClick = () => {
    if (!user.isLoggedIn) {
      return toast({
        description: 'Du musst eingeloggt sein um diese Aktion auszuführen',
        status: 'info',
        duration: 3000,
      })
    }
    controls.start({
      scale: [1, 1.4, 1.2, 1],
      transition: { duration: 0.35 },
    })

    if (isBookmarked) {
      user.unbookmark?.(id, type)
    } else {
      user.bookmark?.(id, type)
    }

    setIsBookmarked(!isBookmarked)
    onClick?.()
  }
  return (
    <MotionFlex
      animate={controls}
      justifyContent="center"
      alignItems="center"
      onClick={handleClick}
      cursor="pointer"
      userSelect="none"
      color={isBookmarked ? 'yellow.400' : 'inherit'}
      _hover={{ color: 'yellow.400' }}
    >
      <Box as="span" transition="color .35s">
        <Icon w="32px" h="32px" as={isBookmarked ? MdOutlineBookmarkRemove : MdOutlineBookmarkAdd}/>
      </Box>
    </MotionFlex>
  )
}
