/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Avatar, Container, Flex, Skeleton, Text } from '@chakra-ui/react'
import { IonContent, IonPage } from '@ionic/react'
import React from 'react'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { useUserBookmarks } from '../gql/queries/getIsBookmarked'
import { getCompressedImageUrl } from '../helper/asset'
import { useUser } from '../helper/auth'

const BookmarkList: React.FC = () => {
  const user = useUser()
  const { data, loading } = useUserBookmarks(user.data?.user?.id ?? '0')
  if (!user.loading && !user.isLoggedIn) return <Redirect to="/login"/>

  return (
    <IonPage>
      <IonContent fullscreen>
        <Container maxW="container.md" py="2em">
          <Skeleton isLoaded={!loading}>
            {data?.getTribelloBookmarkListing?.edges?.map(bookmark => (
              <Flex
                as={Link}
                key={bookmark?.node?.id}
                borderRadius="8px"
                bg="var(--ion-card-background)"
                m="16px"
                p="1em"
                flexDir="row"
                alignItems="center"
                cursor="pointer"
                to={
                  (bookmark?.node?.hotel && `/hotels/${bookmark.node.hotel.id ?? ''}`) ||
                  (bookmark?.node?.post && `/posts/${bookmark.node.post.id ?? ''}`) ||
                  // @eslint-disable-next-line
                  (bookmark?.node?.inspiration && `/inspirations/${bookmark.node.inspiration.id ?? ''}`) ||
                '/'
                }
              >
                {!bookmark?.node?.inspiration && (
                  <Avatar src={bookmark?.node?.hotel
                    ? getCompressedImageUrl(bookmark.node.hotel.images?.[0]?.fullpath)
                    : getCompressedImageUrl(bookmark?.node?.post?.user?.customer?.images?.[0]?.fullpath)}
                  />
                )}
                <Text ml={4}>{bookmark?.node?.hotel?.name ? bookmark.node.hotel.name : bookmark?.node?.post?.headline ? bookmark.node.post.headline : bookmark?.node?.inspiration?.name}</Text>
              </Flex>
            ))}
          </Skeleton>
        </Container>
      </IonContent>
    </IonPage>
  )
}

export default BookmarkList
