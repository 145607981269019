import { Box, Flex, Grid, GridItem, Icon, Portal, Text, useBreakpointValue } from '@chakra-ui/react'
import { css } from '@emotion/css'
import { IonButton, IonIcon } from '@ionic/react'
import { apps } from 'ionicons/icons'
import React, { useMemo, useState } from 'react'
import { MdBookmarkBorder, MdFingerprint, MdInfoOutline, MdOutlineChat, MdOutlinePublic, MdPersonOutline } from 'react-icons/md'
import { useUser } from '../../helper/auth'

const MoreButton: React.FC = () => {
  const user = useUser()
  const [menuVisible, setMenuVisible] = useState(false)
  const right = useBreakpointValue({ base: '5', lg: '50%', xl: '50%', '2xl': '50%' })
  const marginRight = useBreakpointValue({ base: '0', lg: '-384px', xl: '-384px', '2xl': '-384px' })
  const menuItems = useMemo(() => [
    { icon: MdOutlineChat, label: 'Kontakt', route: 'mailto:tribello@eurotours.at' },
    { icon: MdInfoOutline, label: 'Impressum', route: '/content/impressum' },
    { icon: MdFingerprint, label: 'Datenschutz', route: '/content/datenschutzerklärung' },
    { icon: MdInfoOutline, label: 'AGB', route: '/content/agb' },
    { icon: MdPersonOutline, label: user.isLoggedIn ? 'Profil' : 'Login', route: user.isLoggedIn ? `/profile/${user.data?.user?.id ?? 0}` : '/login' },
    ...(user.isLoggedIn ? [{ icon: MdBookmarkBorder, label: 'Merkliste', route: '/saved' }] : [{}]),
    { icon: MdOutlinePublic, label: 'Inspirationen', route: '/inspirations' },
  ], [user])

  return (
    <>
      <IonIcon icon={apps}/>
      <Box w="100%" h="100%" pos="absolute" onClick={() => setMenuVisible(!menuVisible)}/>
      {menuVisible && (
        // this is ultra bad practice, but since ionic is dogshit, there is no other (easy) way
        <Portal>
          <Box
            pos="absolute"
            background="var(--ion-card-background)"
            borderRadius="8px"
            className="hey find me"
            bottom="60px"
            right={right}
            marginRight={marginRight}
            boxShadow="0 1px 20px rgb(43 50 88 / 30%)"
          >
            <Grid templateRows="repeat(2, 74px)" templateColumns="repeat(3, 74px)" gap={2} padding={2} gridAutoFlow="dense">
              {menuItems.map(item => item.label && (
                <GridItem key={item.label}>
                  <IonButton
                    href={item.route}
                    target={item.route.startsWith('mailto:') ? '_blank' : undefined}
                    fill="clear"
                    className={css`
                      --padding-start: 0;
                      --padding-end: 0;
                      --padding-top: 0;
                      --padding-bottom: 0;
                      --color: var(--ion-color-step-600);
                      --color-activated: var(--ion-color-primary);
                      --color-focused: var(--ion-color-primary);
                      --color-hover: var(--ion-color-primary);
                      width: 100%;
                      height:100%;
                    `}
                  >
                    <Flex flexDir="column" align="center" justify="center">
                      {/* <ChakraIcon icon={item.icon} fontSize="2xl"/> */}
                      <Icon w="24px" h="24px" as={item.icon}/>
                      <Text fontWeight="bold" marginTop={2} fontSize=".6em">{item.label}</Text>
                    </Flex>
                  </IonButton>
                </GridItem>
              ))}
            </Grid>
          </Box>
        </Portal>
      )}

    </>
  )
}

export default MoreButton
