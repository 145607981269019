import { CheckCircleIcon } from '@chakra-ui/icons'
import { Box, Divider, Flex, Heading, Input, Skeleton, Tag, TagLabel, TagRightIcon, useColorModeValue, VStack } from '@chakra-ui/react'
import Downshift from 'downshift'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import { putTag } from '../../gql/mutations/putTag'
import { getAllTags, useAllTagTypes } from '../../gql/queries/getTags'
import type { getTribelloTagTagsByType_tagsByTagType_edges_tags as ITag } from '../../gql/queries/__generated__/getTribelloTagTagsByType'
import { calculateTagColor } from '../../helper/tags'

const TagSelect: React.FC<{enableTagCreation?: boolean, showHiddenTagsInSearch: boolean, tags: number[], setTags: (value: React.SetStateAction<number[]>) => void}> = ({ enableTagCreation, showHiddenTagsInSearch, tags, setTags }) => {
  const { loading: allTagsLoading, value: allTags, retry } = useAsyncRetry(async () => await getAllTags(showHiddenTagsInSearch))
  const { loading: tagTypesLoading, data: tagTypes } = useAllTagTypes(false)
  const [selectedTags, setSelectedTags] = useState<ITag[]>([])
  const inputRef = useRef<HTMLInputElement>(null)
  const selectedColor = useColorModeValue('blackAlpha.100', 'whiteAlpha.100')

  // set selected state on initial render
  useEffect(() => renderSelectedTags(), [allTagsLoading])

  // update selected
  const renderSelectedTags = () => {
    const postSelected = allTags?.filter(tag => tags.includes(Number(tag.id ?? 0))) ?? []
    // set selected tags from the post to state
    setSelectedTags(prevTags => [...prevTags, ...postSelected])
  }

  const allTagsNonNull = useMemo(() => allTags?.filter((item): item is NonNullable<typeof item> => Boolean(item)) ?? [], [allTags])

  const handleTagSelect = useCallback(({ tag, tagId }: {tag?: ITag, tagId?: string}) => {
    if (tagId) tag = allTags?.find(e => e.id === tagId)
    if (tag) setSelectedTags(selectedTags.some(searchTag => searchTag.id === tag?.id) ? [...selectedTags.filter(f => f.id !== tag?.id)] : [...selectedTags, tag])
    setTags(tags.includes(Number(tag?.id)) ? [...tags.filter(id => id !== Number(tag?.id))] : [...tags, Number(tag?.id)])
    if (inputRef.current) inputRef.current.value = ''
  }, [allTags, selectedTags, setTags, tags, inputRef])

  const handleCreateTag = useCallback(async (name: string) => {
    const res = await putTag(name)
    retry()
    if (res.data.createTribelloTag?.output?.tagType) {
      const { id, name, tagType } = res.data.createTribelloTag.output
      const { id: typeId, description, name: typeName } = tagType
      setTags(tags.includes(Number(id)) ? [...tags.filter(id => id !== Number(id))] : [...tags, Number(id)])
      setSelectedTags([...selectedTags, { __typename: 'object_TribelloTag', id, name, tagType: { __typename: 'object_TribelloTagType', id: typeId, hidden: false, name: typeName, description } }])
    }
  }, [retry, selectedTags, setTags, tags])

  return (
    // overflow is a hack because ionic modal is not scrollable otherwise
    <Box p={5} w="100%" h="100%" overflowY="auto">
      <VStack align="flex-start">
        <Heading>Ausgewählte Tags</Heading>
        {selectedTags.length > 0
          ? (
            <Flex w="full" overflowX="auto" gap={2} flexWrap="wrap">
              {selectedTags.map(tag => (
                <Tag
                  cursor="pointer"
                  size="lg"
                  borderRadius="full"
                  onClick={() => handleTagSelect({ tag })}
                  colorScheme={calculateTagColor(tag.tagType?.name ?? '')}
                  key={tag.id}
                  display="flex"
                  justifyContent="space-between"
                >
                  <TagLabel>{tag.name}</TagLabel>
                  {selectedTags.some(searchTag => searchTag.id === tag.id) && <TagRightIcon><CheckCircleIcon/></TagRightIcon>}
                </Tag>
              ))}
            </Flex>
            )
          : <>Keine Tags ausgewählt</>}
        <Heading>Suchen</Heading>
        <Skeleton isLoaded={!allTagsLoading} w="full"/>
        <Skeleton isLoaded={!tagTypesLoading}>
          <VStack spacing={3} align="flex-start">
            <Downshift
              onChange={(selectedItem: ITag & {createNew?: boolean} | null) => {
                if (selectedItem?.createNew) {
                  handleCreateTag(selectedItem.name ?? '')
                } else { handleTagSelect({ tagId: selectedItem?.id ?? '' }) }
              }}
              itemToString={(item) => (item ? item.name ?? '' : '')}
            >
              {({
                getInputProps,
                getItemProps,
                getMenuProps,
                getLabelProps,
                getToggleButtonProps,
                inputValue,
                highlightedIndex,
                selectedItem,
                getRootProps,
                isOpen,
              }) => (
                <Box w="full">
                  <div {...getRootProps(undefined, { suppressRefError: true })}>
                    <label {...getLabelProps()}>Suche nach Tags</label>
                    <Input w="full" {...getInputProps()}/>
                  </div>
                  <VStack
                    position="absolute"
                    width="full"
                    bgColor="var(--ion-toolbar-background)"
                    w="full"
                    spacing={3}
                    {...getMenuProps()}
                  >
                    {isOpen
                      ? (
                        <>
                          {allTagsNonNull.filter(item => !inputValue || item.name?.toLowerCase().includes(inputValue.toLowerCase()))
                            .map((item, index) => (
                              <Flex
                                w="full"
                                justify="flex-start"
                                borderRadius="md"
                                marginInlineStart={2}
                                marginInlineEnd={2}
                                paddingInlineStart={2}
                                paddingInlineEnd={2}
                                paddingTop={2}
                                paddingBottom={2}
                                gap={2}
                                cursor="pointer"
                                bgColor={highlightedIndex === index && selectedColor}
                                key={item.id}
                                {...getItemProps({
                                  item,
                                  index,
                                })}
                              >
                                <span>{item.name}</span>
                              </Flex>
                            ))}
                          {enableTagCreation && (
                            <>
                              <Divider/>
                              <Flex
                                w="full"
                                justify="flex-start"
                                borderRadius="md"
                                marginInlineStart={2}
                                marginInlineEnd={2}
                                paddingInlineStart={2}
                                paddingInlineEnd={2}
                                paddingTop={2}
                                paddingBottom={2}
                                bgColor={highlightedIndex !== null && highlightedIndex >= allTagsNonNull.filter(item => !inputValue || item.name?.toLowerCase().includes(inputValue.toLowerCase())).length && selectedColor}
                                cursor="pointer"
                                {...getItemProps({
                                  item: { createNew: true, name: inputValue, id: '', tagType: null, __typename: 'object_TribelloTag' },
                                })}
                              >
                                <span>{inputValue} hinzufügen</span>
                              </Flex>
                            </>
                          )}
                        </>
                        )
                      : null}
                  </VStack>
                </Box>
              )}

            </Downshift>
            {tagTypes?.allTagTypes?.edges?.map((tagType, i) => (
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              <Box key={`${tagType?.name ?? ''}-${i}`} w="full">
                <Heading mb={3}>{tagType?.name}</Heading>
                <Skeleton isLoaded={!allTagsLoading} display={['flex', 'flex', 'grid']} flexWrap={['wrap', 'wrap', null]} gap="2" gridTemplateColumns="repeat(3, 1fr)">
                  {allTags?.filter(tag => Number(tag.tagType?.id) === Number(tagType?.id))?.map((tag, i) => (
                    <Tag
                      cursor="pointer"
                      size="lg"
                      borderRadius="full"
                      onClick={() => handleTagSelect({ tag })}
                      colorScheme={calculateTagColor(tag.tagType?.name ?? '')}
                      key={tag.id}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <TagLabel>{tag.name}</TagLabel>
                      {selectedTags.some(searchTag => searchTag.id === tag.id) && <TagRightIcon><CheckCircleIcon/></TagRightIcon>}
                    </Tag>
                  ))}
                </Skeleton>
              </Box>
            ))}
          </VStack>
        </Skeleton>
      </VStack>
    </Box>

  )
}
export default TagSelect
