import { createNotification } from '../gql/mutations/putNotification'
import { getAuthToken } from './auth'

const createMention = (id: string, commentText: string) => {
  const matches = commentText.matchAll(/@\[(.+?)\]\((\d+?)\)/g)

  for (;;) {
    const match = matches.next()
    if (match.done) break

    // only need userId for now
    const [matchedString, username, userId] = match.value

    const matchIndex = match.value.index
    if (typeof matchIndex === 'undefined') continue

    // send mention notification to mentioned user
    createNotification(getAuthToken(), +id, +userId, 'mentioned')
  }
}

export default createMention
