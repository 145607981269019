import { Box, Container, Flex, Heading, HStack, VStack } from '@chakra-ui/layout'
import { Avatar, Button, Input } from '@chakra-ui/react'
import { Textarea } from '@chakra-ui/textarea'
import { IonContent, IonIcon, IonModal } from '@ionic/react'
import { close } from 'ionicons/icons'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import type { ICardProps } from '../../..'
import { useUpdatePost } from '../../../../../gql/mutations/putUpdatePost'
import { getCompressedImageUrl } from '../../../../../helper/asset'
import { getAuthToken, useUser } from '../../../../../helper/auth'
import TagSelect from '../../../../TagSelect'

export interface IHeaderEditProps extends ICardProps {
  showModal: boolean
  setShowModal: (state: boolean) => void
}

const PostHeaderEdit: React.FC<IHeaderEditProps> = ({ showModal, setShowModal, post, onEdit }) => {
  const textRef = useRef<HTMLTextAreaElement>(null)
  const [tags, setTags] = React.useState<number[]>([])
  const [updatePost] = useUpdatePost()
  const [headline, setHeadline] = useState(post?.headline)
  const user = useUser()

  useEffect(() => {
    setTags(post?.tags?.map((tag) => Number(tag?.id)) ?? [])
  }, [post?.tags])

  const handleEdit = useCallback(async () => {
    if (textRef.current) {
      onEdit?.(textRef.current.value, headline!, tags)
      setShowModal(false)

      if (post?.id && user.data) {
        await updatePost({
          variables: {
            userAccessToken: getAuthToken(),
            postId: Number(post.id),
            description: textRef.current.value,
            headline,
            tags,
          },
        })
      }
    }
  }, [onEdit, setShowModal, post, user, updatePost, tags, headline])

  return (
    <IonModal
      isOpen={showModal}
      swipeToClose={true}
      onDidDismiss={() => setShowModal(false)}
    >
      <IonContent>
        <Container
          pt="2em"
          onClick={e => e.stopPropagation()}
        >
          <VStack align="stretch">
            <HStack justifyContent="space-between">
              <Heading size="lg">Beitrag Bearbeiten</Heading>
              <Button onClick={() => setShowModal(false)} variant="ghost">
                <IonIcon icon={close}/>
              </Button>
            </HStack>
            <HStack spacing={5} width="100%" align="flex-start">
              <Box><Avatar src={getCompressedImageUrl(user.data?.user?.customer?.images?.[0]?.fullpath)}/></Box>
              <Box w="100%">
                <Heading size="md">{user.data?.user?.customer?.firstname} {user.data?.user?.customer?.lastname}</Heading>
                <Input
                  _focus={{ outline: 'none' }}
                  outline="none"
                  border="none"
                  paddingInlineStart={0}
                  pt={4}
                  pb={4}
                  placeholder="Titel"
                  onChange={e => setHeadline(e.target.value)}
                  value={headline!}
                />
                <Textarea
                  autoFocus
                  ref={textRef}
                  as={TextareaAutosize}
                  resize="none"
                  defaultValue={post?.description ?? ''}
                  onFocus={e => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                  fontWeight="light"
                  borderWidth={0}
                  p="7px 0 0 0"
                  outline="none"
                  _focus={{ outline: 'none' }}
                />
              </Box>
            </HStack>
            <Flex alignSelf="center" w="100%">
              <TagSelect showHiddenTagsInSearch={true} enableTagCreation={true} tags={tags} setTags={setTags}/>
            </Flex>
            <HStack justifyContent="flex-end">
              <Button variant="ghost" onClick={() => setShowModal(false)}>
                Abbrechen
              </Button>
              <Button onClick={handleEdit}>
                Speichern
              </Button>
            </HStack>
          </VStack>
        </Container>
      </IonContent>
    </IonModal>
  )
}

export default PostHeaderEdit
