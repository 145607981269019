import { Box, Center, Flex, Image, Skeleton, useColorModeValue, chakra } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { IonIcon } from '@ionic/react'
import type { AnimationControls } from 'framer-motion'
import { heart, volumeHighOutline, volumeMuteOutline } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import SwiperCore, { Navigation, Pagination, Virtual, Zoom, Scrollbar } from 'swiper'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/scrollbar/scrollbar.min.css'
import 'swiper/modules/zoom/zoom.min.css'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import type { ICardProps } from '..'
import type { getPostQuery_getTribelloPost_images as IMedia } from '../../../gql/queries/__generated__/getPostQuery'
import { getAssetUrl, getCompressedImageUrl } from '../../../helper/asset'
import { MotionFlex } from '../../Motion'
import Video from '../../Video'
const ChakraIcon = chakra(IonIcon)

SwiperCore.use([Zoom, Navigation, Pagination, Virtual, Scrollbar])

const PostMedia: React.FC<ICardProps & { animationControl: AnimationControls }> = ({ post, animationControl }) => {
  const imageBg = useColorModeValue('gray.50', '#181818')
  const [media, setMedia] = useState<Array<IMedia | null>>([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [videoMuted, setVideoMuted] = useState(true)

  useEffect(() => {
    setMedia([
      ...(post?.images ?? []),
      ...(post?.videos ?? []),
    ])
  }, [post])

  if (!post) return <Skeleton h={['200px', '300px', '400px']} w="100%"/>
  if (!post.images && !post.videos) return null

  return (
    <Box
      onClick={e => e.stopPropagation()}
      bg={imageBg}
      css={css`
        .swiper-button-next, .swiper-button-prev {
          -webkit-transform: translate3d(0, 0, 0); 
          transform: translate3d(0, 0, 0)
        }
      `}
    >
      <Swiper
        zoom={{
          maxRatio: 5,
          minRatio: 1,
        }}
        scrollbar={{ hide: true }}
        navigation
        style={{ width: '100%' }}
        virtual={true}
        preloadImages={false}
        onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      >
        {
          media.map((postMedia, index) => postMedia?.fullpath && postMedia.mimetype && (
            <SwiperSlide key={index}>
              <Flex
                position="relative"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                cursor="pointer"
                w="100%"
                css={css`
                  &::after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                  }
                `}
              >
                <MotionFlex
                  initial={{ opacity: 0 }}
                  animate={animationControl}
                  pointerEvents="none"
                  position="absolute"
                  color="red.400"
                  fontSize="150px"
                  w="100%"
                  height="100%"
                  top="0"
                  left="0"
                  justifyContent="center"
                  alignItems="center"
                  zIndex={3}
                >
                  <IonIcon icon={heart}/>
                </MotionFlex>
                {
                  postMedia.mimetype.startsWith('image')
                    ? (
                      <Box className="swiper-zoom-container">
                        <Image
                          w="100%"
                          src={getCompressedImageUrl(postMedia.fullpath)}
                          key={index}
                        />
                      </Box>
                      )
                    : (
                      <Box w="100%">
                        <ChakraIcon zIndex="14" icon={videoMuted ? volumeMuteOutline : volumeHighOutline} onClick={() => setVideoMuted(!videoMuted)} bottom={2} right={2} size="large" alignSelf="flex-end" pos="absolute"/>
                        <Video playsInline muted={videoMuted} loop active={activeIndex === index} src={getAssetUrl(postMedia.fullpath)}/>
                      </Box>
                      )
                  }
              </Flex>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </Box>
  )
}

export default PostMedia
