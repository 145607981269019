import { gql, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { getTribelloTagsByIdQuery, getTribelloTagsByIdQueryVariables } from './__generated__/getTribelloTagsByIdQuery'
import type { getTribelloTagTagsByType, getTribelloTagTagsByTypeVariables, getTribelloTagTagsByType_tagsByTagType_edges_tags } from './__generated__/getTribelloTagTagsByType'
import type { getTribelloTagTypes } from './__generated__/getTribelloTagTypes'

// TODO: Not automatically generated, needs to be updated manually
export type ITagType = 'region' | 'interest' | 'media' | 'category' | 'type' | 'other' | ''

const TAG_TYPE_FRAGMENT = gql`
fragment TagTypeFragment on object_TribelloTagType {
  id
  hidden
  description
  name
}
`

const TAG_FRAGMENT = gql`
fragment TagFragment on object_TribelloTag{
  id
  name
  tagType {
   ...TagTypeFragment
  }
}
${TAG_TYPE_FRAGMENT}
`

const GET_ALL_TAG_TYPES = gql`
  query getTribelloTagTypes($showHidden: Boolean!) {
    allTagTypes (showHidden: $showHidden) {
      edges{
        ...TagTypeFragment
      }
    }
  }
  ${TAG_TYPE_FRAGMENT}
`

const GET_TAGS_BY_TYPE = gql`
query getTribelloTagTagsByType ($showHidden: Boolean!, $type: String) {
  tagsByTagType(type: $type, showHidden: $showHidden) {
    edges {
      tags {
        ...TagFragment
      }
    }
  }
}
${TAG_FRAGMENT}
`
const GET_TAGS_BY_ID = gql`
query getTribelloTagsByIdQuery($ids: String!) {
  getTribelloTagListing(ids: $ids) {
    edges {
      node {
        ...TagFragment
      }
    }
  }
}
${TAG_FRAGMENT}
`

export async function getTagsByTagType (showHidden: boolean, type?: string) {
  return apolloClient.query<getTribelloTagTagsByType, getTribelloTagTagsByTypeVariables>({
    query: GET_TAGS_BY_TYPE,
    variables: { type, showHidden },
  })
}

export async function getTagsById (ids: string) {
  return apolloClient.query<getTribelloTagsByIdQuery, getTribelloTagsByIdQueryVariables>({
    query: GET_TAGS_BY_ID,
    variables: { ids },
  })
}

export function useTagsByTagType (showHidden: boolean, type?: string) {
  return useQuery<getTribelloTagTagsByType, getTribelloTagTagsByTypeVariables>(GET_TAGS_BY_TYPE, { variables: { type, showHidden } })
}
// Syntactic sugar for readability
export function useAllTags (showHidden: boolean) {
  return useTagsByTagType(showHidden)
}

export async function getAllTagTypes (showHidden: boolean) {
  return apolloClient.query<getTribelloTagTypes>({
    query: GET_ALL_TAG_TYPES,
    variables: { showHidden },
  })
}

export function useAllTagTypes (showHidden: boolean) {
  return useQuery<getTribelloTagTypes, getTribelloTagTagsByTypeVariables>(GET_ALL_TAG_TYPES, {
    variables: { showHidden },
  })
}

export async function getAllTags (showHidden: boolean) {
  const { data } = await getTagsByTagType(showHidden)
  return data.tagsByTagType?.edges?.flat().map(e => e?.tags).flat().filter((tag): tag is NonNullable<getTribelloTagTagsByType_tagsByTagType_edges_tags | null | undefined> => Boolean(tag?.tagType?.id))
}
